.joinLink {
  margin-top: 45px;
  text-decoration: none;
  background: #ff4f5e;
  transition: background-color 0.2s linear, color 0.2s linear;
  mix-blend-mode: normal;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  box-shadow: inset 0 0 0 3px #000;

  width: 180px;
  height: 66px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Copperplate";
  font-size: 16px;

  color: #1c1c1c;
}
@font-face {
  font-family: "Copperplate";
  src: url("./assets/fonts/Copperplate.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
}

@media (max-width: 768px) {
  .joinLink {
    margin-top: 8px;
    font-weight: 400;
    width: 174px;
    height: 31px;
  }
}
