.termsInfo {
  padding: 50px;
  font-family: Arial, sans-serif;
  color: #000;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .termsInfo {
    padding: 20px 100px;
  }
}

.termsInfo h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-decoration: underline;
}

.termsInfo h2 {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.termsInfo p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.termsInfo a {
  text-decoration: none;
  color: #ff4f5e;
  font-weight: 400;
}

.termsInfo a:hover {
  text-decoration: underline;
}
