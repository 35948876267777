.cookieBar {
  background-color: #e4e4e4;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 900px;
  margin: 0 auto;
}

.cookieBar p {
  margin: 0;
  font-size: 0.9em;
  color: #333;
  flex: 1;
}

.cookieBar a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.cookieBar a:hover {
  text-decoration: underline;
}

.confirmButton {
  background-color: #ff4f5e;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 15px;
}

.confirmButton:hover {
  background-color: #f95a68;
}

/* Media Query for smaller devices */
@media (max-width: 768px) {
  .cookieBar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .cookieBar p {
    margin-bottom: 10px;
    text-align: center;
  }

  .confirmButton {
    width: auto;
  }
}
