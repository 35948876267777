/* Home.module.css */

.container {
  min-height: 35vh;
  height: 800px;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  margin-top: 72px;
}

.container_git {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url("../public/header-bg-mb.png");
}

.content {
  width: 90%;
}
.headingOffer {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;

  line-height: 42px;
  padding: 0;
  margin: 0;
  font-weight: 700;
}
.headingBirthday {
  font-family: "Helvetica", sans-serif;
  font-size: 37px;
  padding: 0;
  margin: 16px 0 0 0;
  font-weight: 700;
}
.line {
  padding: 32px 0;
}

.headingDate {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;

  line-height: 42px;
  padding: 0;
  margin: 0;

  margin-bottom: 25px;
  font-weight: 700;
}
.linkReadMore {
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  font-size: 14px;
  color: #0e0909;
  text-decoration: underline;
  padding: 0;
  font-weight: 300;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: 700;
}
.readmorecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gametypes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.gametype {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.gametype img {
  width: 100%;
}
@media (max-width: 768px) {
  .gametypes {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .gametype {
    width: 90%;
  }
  .content {
    width: 334px;
    min-height: none;
  }
  .container {
    min-height: none;
    width: 100%;
    margin: 0;

    height: 1300px;
  }
  .headingOffer {
    font-size: 13.78px;
    margin-top: 31px;
  }
  .headingBirthday {
    font-size: 21px;
    margin-top: 0;
  }
  .line {
    width: 48px;
    padding: 16px 0;
  }
  .headingDate {
    margin: 0;
  }
}
