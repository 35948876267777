.mobileLink {
  display: none;
}
@media (max-width: 768px) {
  .mobileLink {
    display: block;
  }
}

.logoHeader {
  height: auto;
  width: 100px;
  background: #ff4f5e;
  border-radius: 100px;
  transition: background-color 0.2s linear, color 0.2s linear;
  mix-blend-mode: normal;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 3px #000;
}
