/* Header.module.css */
.header {
  background-image: url("../public/Hero.webp");
  background-size: cover;
  background-position: center;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Adjust text size as needed */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: normal;

  flex-direction: column;
}
.overlayTop {
  margin: 0;
  background: linear-gradient(
    to bottom,
    rgba(14, 14, 14, 1) 0%,
    rgba(19, 19, 19, 0) 100%
  );

  width: 100%;
  height: 137px;
}
.overlayBottom {
  margin: 0;
  background: linear-gradient(
    to top,
    rgba(14, 14, 14, 1) 0%,
    rgba(19, 19, 19, 0) 100%
  );
  width: 100%;
  height: 317px;
}
.headerTop {
  width: 84.17%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  z-index: 133;

  font-family: "Helvetica", sans-serif;
}
.links {
  width: 158px;
  display: flex;
  justify-content: space-between;
  height: 14px;
}
.links a {
  font-family: "Helvetica Regular", sans-serif;
  font-weight: normal;
  letter-spacing: 1.44px;
  color: white; /* Adjust text color for better visibility */
  font-size: 12px;
  text-decoration: none;
}

.headerBottom {
  /* margin-top: 44px; */
  margin-top: 404px;
  z-index: 1333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerBottom h1 {
  font-family: "Helvetica", sans-serif;
  font-size: 37px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px black;
  padding: 0;
  margin: 18px 0 0 0;
  font-weight: 700;
}
@media (max-width: 768px) {
  .overlay {
    height: 100vh;
  }
  .headerTop {
    width: 90%;
    margin-top: 8px;
  }
  .header {
    height: 100vh;
    background-size: cover;
    background-image: url("../public/Hero.webp");
  }
  .links {
    display: none;
  }
  .line {
    display: none;
  }

  .header .logoHeader {
    height: auto;
    width: 75px;
    background: #ff4f5e;
    border-radius: 100px;
    transition: background-color 0.2s linear, color 0.2s linear;
    mix-blend-mode: normal;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 3px #000;
  }

  .headerBottom {
    display: flex;
    margin: 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 28px;
  }
  .headerBottom h1 {
    padding: 0;
    margin: 0;
    font-size: 20px;

    padding: 22px 0;
  }
}

@media screen and (min-width: 1200px) {
  .logoHeader {
    width: 100px;
    background: #ff4f5e;
    border-radius: 100px;
    transition: background-color 0.2s linear, color 0.2s linear;
    mix-blend-mode: normal;
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 3px #000;
  }
}
